// assets
import {
  IconUsers,
  IconMoneybag,
  IconAffiliate,
  IconFreeRights,
  IconTrolley,
  IconCashOff,
  IconBulb,
  IconReport,
  IconShoppingCart,
  IconCoins,
  IconBuildingSkyscraper,
  IconGridGoldenratio,
} from '@tabler/icons-react';

// constant
const icons = {
  IconUsers,
  IconMoneybag,
  IconAffiliate,
  IconFreeRights,
  IconTrolley,
  IconCashOff,
  IconReport,
  IconBulb,
  IconShoppingCart,
  IconCoins,
  IconBuildingSkyscraper,
  IconGridGoldenratio,
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
  id: 'pages',
  title: 'Pages',
  type: 'group',
  access: ['super_admin', 'admin', 'user'],
  children: [
    {
      id: 'client-management',
      title: 'Client Management',
      type: 'collapse',
      color: '#2F0F5D',
      icon: icons.IconUsers,
      access: ['super_admin'],
      children: [
        {
          id: 'client-groups',
          title: 'Client Groups',
          type: 'item',
          url: '/pages/client-management/client-groups',
          access: ['super_admin'],
        },
        {
          id: 'all-clients',
          title: 'All Clients',
          type: 'item',
          url: '/pages/client-management/all-clients',
          access: ['super_admin'],
        },
      ],
    },
    {
      id: 'sales-management',
      title: 'Sales Management',
      type: 'collapse',
      color: '#2F0F5D',
      icon: icons.IconShoppingCart,
      access: ['super_admin'],
      children: [
        {
          id: 'products',
          title: 'Products',
          type: 'item',
          url: '/pages/sales-management/products',
          access: ['super_admin'],
        },
        {
          id: 'sales-orders',
          title: 'Sales Orders',
          type: 'item',
          url: '/pages/sales-management/sales-orders',
          access: ['super_admin'],
        },
        {
          id: 'invoices',
          title: 'Invoices',
          type: 'item',
          url: '/pages/sales-management/invoices',
          access: ['super_admin'],
        },
        {
          id: 'custom-invoices',
          title: 'Custom Invoices',
          type: 'item',
          url: '/pages/sales-management/custom-invoices',
          access: ['super_admin'],
        },
      ],
    },

    {
      id: 'financial',
      title: 'Financial',
      type: 'collapse',
      color: '#2F0F5D',
      icon: icons.IconMoneybag,
      access: ['super_admin'],
      children: [
        {
          id: 'account-types',
          title: 'Account Types',
          type: 'item',
          url: '/pages/financial/account-types',
          access: ['super_admin'],
        },
        {
          id: 'account-heads',
          title: 'Account Heads',
          type: 'item',
          url: '/pages/financial/account-heads',
          access: ['super_admin'],
        },
        {
          id: 'payment-methods',
          title: 'Payment Methods',
          type: 'item',
          url: '/pages/financial/payment-methods',
          access: ['super_admin'],
        },
        {
          id: 'receive-payment',
          title: 'Receive a Payment',
          type: 'item',
          url: '/pages/financial/receive-payment',
          access: ['super_admin'],
        },
        // {
        //   id: 'make-payment',
        //   title: 'Make a Payment',
        //   type: 'item',
        //   url: '/pages/financial/make-payment',
        //   access: ['super_admin'],
        // },
        // {
        //   id: 'bills',
        //   title: 'Bills',
        //   type: 'item',
        //   url: '/pages/financial/bills',
        //   access: ['super_admin'],
        // },
        {
          id: 'fixed-asset',
          title: 'Fixed Asset',
          type: 'item',
          url: '/pages/financial/fixed-asset',
          access: ['super_admin'],
        },
        {
          id: 'investment',
          title: 'Investment',
          type: 'item',
          url: '/pages/financial/investment',
          access: ['super_admin'],
        },
        {
          id: 'withdraw',
          title: 'Withdraw',
          type: 'item',
          url: '/pages/financial/withdraw',
          access: ['super_admin'],
        },
      ],
    },
    {
      id: 'store-management',
      title: 'Store Management',
      type: 'collapse',
      color: '#2F0F5D',
      icon: icons.IconTrolley,
      access: ['super_admin'],
      children: [
        {
          id: 'uom',
          title: 'Unit of Measurement',
          type: 'item',
          url: '/pages/store-management/uom',
          access: ['super_admin'],
        },
        {
          id: 'items-name',
          title: 'Items Name',
          type: 'item',
          url: '/pages/store-management/items-name',
          access: ['super_admin'],
        },
        {
          id: 'vendors',
          title: 'Vendors',
          type: 'item',
          url: '/pages/store-management/vendors',
          access: ['super_admin'],
        },

        {
          id: 'items-in',
          title: 'Items (Receive)',
          type: 'item',
          url: '/pages/store-management/items-in',
          access: ['super_admin'],
        },
        {
          id: 'items-out',
          title: 'Items (Delivery)',
          type: 'item',
          url: '/pages/store-management/items-out',
          access: ['super_admin'],
        },
        {
          id: 'stock-status',
          title: 'Stock Status',
          type: 'item',
          url: '/pages/store-management/stock-status',
          access: ['super_admin'],
        },
      ],
    },
    {
      id: 'expense-management',
      title: 'Expense Management',
      type: 'collapse',
      color: '#2F0F5D',
      icon: icons.IconCashOff,
      access: ['super_admin'],
      children: [
        {
          id: 'expense-heads',
          title: 'Expense Heads',
          type: 'item',
          url: '/pages/expense-management/expense-heads',
          access: ['super_admin'],
        },
        {
          id: 'all-expenses',
          title: 'All Expenses',
          type: 'item',
          url: '/pages/expense-management/all-expenses',
          access: ['super_admin'],
        },
      ],
    },
    {
      id: 'manage-distributor',
      title: 'Manage Distributor',
      type: 'collapse',
      color: '#2F0F5D',
      icon: icons.IconAffiliate,
      access: ['super_admin'],
      children: [
        {
          id: 'distributors',
          title: 'Distributors',
          type: 'item',
          url: '/pages/manage-distributor/distributors',
          access: ['super_admin'],
        },
        {
          id: 'dr-products',
          title: 'Products',
          type: 'item',
          url: '/pages/manage-distributor/dr-products',
          access: ['super_admin'],
        },
        {
          id: 'dr-invoices',
          title: 'Invoices',
          type: 'item',
          url: '/pages/manage-distributor/dr-invoices',
          access: ['super_admin'],
        },
        {
          id: 'dr-vouchers',
          title: 'Receive Amount',
          type: 'item',
          url: '/pages/manage-distributor/dr-vouchers',
          access: ['super_admin'],
        },
        {
          id: 'dr-summary',
          title: 'Summary',
          type: 'item',
          url: '/pages/manage-distributor/dr-summary',
          access: ['super_admin'],
        },
      ],
    },
    {
      id: 'foc-management',
      title: 'FOC Management',
      type: 'collapse',
      color: '#2F0F5D',
      icon: icons.IconFreeRights,
      access: ['super_admin'],
      children: [
        {
          id: 'foc-products',
          title: 'FOC Products',
          type: 'item',
          url: '/pages/foc-management/foc-products',
          access: ['super_admin'],
        },
        {
          id: 'foc-clients',
          title: 'FOC Clients',
          type: 'item',
          url: '/pages/foc-management/foc-clients',
          access: ['super_admin'],
        },
        {
          id: 'foc-invoices',
          title: 'FOC Invoices',
          type: 'item',
          url: '/pages/foc-management/foc-invoices',
          access: ['super_admin'],
        },
        {
          id: 'foc-summary',
          title: 'FOC Summary',
          type: 'item',
          url: '/pages/foc-management/foc-summary',
          access: ['super_admin'],
        },
      ],
    },
    {
      id: 'building-operations',
      title: 'Building Operations',
      type: 'collapse',
      color: '#2F0F5D',
      icon: icons.IconBuildingSkyscraper,
      access: ['super_admin'],
      children: [
        {
          id: 'operation-expenses',
          title: 'Operation Expenses',
          type: 'item',
          url: '/pages/building-operations/operation-expenses',
          access: ['super_admin'],
        },
        {
          id: 'operation-investments',
          title: 'Investments',
          type: 'item',
          url: '/pages/building-operations/operation-investments',
          access: ['super_admin'],
        },
        {
          id: 'operation-report',
          title: 'Reports',
          type: 'item',
          url: '/pages/building-operations/operation-report',
          access: ['super_admin'],
        },
        {
          id: 'operation-library',
          title: 'Library',
          type: 'item',
          url: '/pages/building-operations/operation-library',
          access: ['super_admin'],
        },
      ],
    },
    {
      id: 'electricity-bills',
      title: 'Electricity Bills',
      type: 'collapse',
      color: '#2F0F5D',
      icon: icons.IconBulb,
      access: ['super_admin'],
      children: [
        {
          id: 'meter-info',
          title: 'Meter Info',
          type: 'item',
          url: '/pages/electricity-bills/meter-info',
          access: ['super_admin'],
        },
        {
          id: 'electric-bills',
          title: 'Electric Bills',
          type: 'item',
          url: '/pages/electricity-bills/electric-bills',
          access: ['super_admin'],
        },
        {
          id: 'electric-overview',
          title: 'Overview',
          type: 'item',
          url: '/pages/electricity-bills/electric-overview',
          access: ['super_admin'],
        },
      ],
    },
    {
      id: 'income-expense',
      title: 'Income Expense',
      type: 'collapse',
      color: '#2F0F5D',
      icon: icons.IconCoins,
      access: ['super_admin'],
      children: [
        {
          id: 'income-expense-category',
          title: 'Category',
          type: 'item',
          url: '/pages/income-expense/income-expense-category',
          access: ['super_admin'],
        },
        {
          id: 'income-head',
          title: 'Income Head',
          type: 'item',
          url: '/pages/income-expense/income-head',
          access: ['super_admin'],
        },
        {
          id: 'expense-head',
          title: 'Expense Head',
          type: 'item',
          url: '/pages/income-expense/expense-head',
          access: ['super_admin'],
        },
        {
          id: 'mode-of-payment',
          title: 'Mode of Payment',
          type: 'item',
          url: '/pages/income-expense/mode-of-payment',
          access: ['super_admin'],
        },
        {
          id: 'personal-income',
          title: 'Income',
          type: 'item',
          url: '/pages/income-expense/personal-income',
          access: ['super_admin'],
        },
        {
          id: 'personal-expense',
          title: 'Expense',
          type: 'item',
          url: '/pages/income-expense/personal-expense',
          access: ['super_admin'],
        },
        {
          id: 'income-expense-summary',
          title: 'Summary',
          type: 'item',
          url: '/pages/income-expense/income-expense-summary',
          access: ['super_admin'],
        },
      ],
    },
    // {
    //   id: 'distribution',
    //   title: 'Distribution',
    //   type: 'collapse',
    //   color: '#2F0F5D',
    //   icon: icons.IconGridGoldenratio,
    //   access: ['super_admin'],
    //   isDistributor: true,
    //   children: [
    //     {
    //       id: 'd-client-list',
    //       title: 'Client List',
    //       type: 'item',
    //       url: '/pages/distribution/d-client-list',
    //       access: ['super_admin'],
    //       isDistributor: true,
    //     },
    //     {
    //       id: 'd-purchased-product',
    //       title: 'Purchased Product',
    //       type: 'item',
    //       url: '/pages/distribution/d-purchased-product',
    //       access: ['super_admin'],
    //       isDistributor: true,
    //     },
    //     {
    //       id: 'd-sales-invoice',
    //       title: 'Sales Invoice',
    //       type: 'item',
    //       url: '/pages/distribution/d-sales-invoice',
    //       access: ['super_admin'],
    //       isDistributor: true,
    //     },
    //     {
    //       id: 'd-receive-payment',
    //       title: 'Receive a Payment',
    //       type: 'item',
    //       url: '/pages/distribution/d-receive-payment',
    //       access: ['super_admin'],
    //       isDistributor: true,
    //     },
    //     {
    //       id: 'd-expense',
    //       title: 'Expenses',
    //       type: 'item',
    //       url: '/pages/distribution/d-expense',
    //       access: ['super_admin'],
    //       isDistributor: true,
    //     },
    //     {
    //       id: 'd-due-report',
    //       title: 'Due Report',
    //       type: 'item',
    //       url: '/pages/distribution/d-due-report',
    //       access: ['super_admin'],
    //       isDistributor: true,
    //     },
    //     {
    //       id: 'd-advance-report',
    //       title: 'Advance Report',
    //       type: 'item',
    //       url: '/pages/distribution/d-advance-report',
    //       access: ['super_admin'],
    //       isDistributor: true,
    //     },
    //     {
    //       id: 'd-summary',
    //       title: 'Summary',
    //       type: 'item',
    //       url: '/pages/distribution/d-summary',
    //       access: ['super_admin'],
    //       isDistributor: true,
    //     },
    //   ],
    // },
    {
      id: 'report',
      title: 'Report',
      type: 'collapse',
      color: '#2F0F5D',
      icon: icons.IconReport,
      access: ['super_admin'],
      children: [
        {
          id: 'summary',
          title: 'Summary',
          type: 'item',
          url: '/pages/report/summary',
          access: ['super_admin'],
        },
        {
          id: 'daily-report',
          title: 'Daily Report',
          type: 'item',
          url: '/pages/report/daily-report',
          access: ['super_admin'],
        },
        {
          id: 'balance-sheet',
          title: 'Balance Sheet',
          type: 'item',
          url: '/pages/report/balance-sheet',
          access: ['super_admin'],
        },
        {
          id: 'due-report',
          title: 'Due Report',
          type: 'item',
          url: '/pages/report/due-report',
          access: ['super_admin'],
        },
        {
          id: 'advance-report',
          title: 'Advance Report',
          type: 'item',
          url: '/pages/report/advance-report',
          access: ['super_admin'],
        },
        // {
        //   id: 'payment-report',
        //   title: 'Payment Report',
        //   type: 'item',
        //   url: '/pages/report/payment-report',
        //   access: ['super_admin'],
        // },
      ],
    },
  ],
};

export default pages;
